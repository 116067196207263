import React from 'react';
import {
  Routes,
  BrowserRouter as Roter,
  Route,
  createBrowserRouter,
  RouterProvider,
  Navigate
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
// import { CompatRouter, Routes, CompatRoute, } from 'react-router-dom-v5-compat';
const Home = React.lazy(() => import('./pages/home'));
const Home1 = React.lazy(() => import('./pages/Home1'));
const Home2 = React.lazy(() => import('./pages/Home2'));
const OneStopWeddingSolution = React.lazy(() => import('./pages/OneStopWeddingSolution'));
const AboutUs = React.lazy(() => import('./pages/aboutUs'));
const ContactUs = React.lazy(() => import('./pages/contactUs'));
const Careers = React.lazy(() => import('./pages/careers'));
const Blogs = React.lazy(() => import('./pages/blogs'));
const Gold = React.lazy(() => import('./pages/gold'));
const Platinum = React.lazy(() => import('./pages/platinum'));
const Letter = React.lazy(() => import('./pages/letter'));
const SignUp = React.lazy(() => import('./pages/signUp'));
const ThankYou = React.lazy(() => import('./pages/thankyou'));
const PrivacyPollicy = React.lazy(() => import('./pages/privacyPollicy'));
const ChildAbusePollicy = React.lazy(() => import('./pages/childAbusePolicy'));
const Cookies = React.lazy(() => import('./pages/cookies'));
const TermsConditions = React.lazy(() => import('./pages/termsConditions'));
const Support = React.lazy(() => import('./pages/support'));
const info = React.lazy(() => import('./pages/info'));
const refund = React.lazy(() => import('./pages/refund'));
const BlogPost = React.lazy(() => import('./pages/blogPost'));
const BlogPost2 = React.lazy(() => import('./pages/blogPost2'));
const BlogPost3 = React.lazy(() => import('./pages/blogPost3'));
const BlogPost4 = React.lazy(() => import('./pages/blogPost4'));
const PaymentStatus = React.lazy(() => import('./pages/PaymentStatus'));
const EventPage = React.lazy(() => import('./pages/EventPage'));
const PaymentSuccess = React.lazy(() => import('./pages/Payment/PaymentSuccess'));
const PaymentFailed = React.lazy(() => import('./pages/Payment/PaymentFailed'));
const OnboardingPage = React.lazy(() => import('./pages/Onboarding/Onboarding'));
const SignupOrSignin = React.lazy(() => import('./pages/SignupOrSignin/SignupOrSignin'));

const SignupEmail = React.lazy(() => import('./pages/SignupEmail/SignupEmail'));
const SigninEmail = React.lazy(() => import('./pages/SigninEmail/SigninEmail'));

const SignupOTP = React.lazy(() => import('./pages/SignupOTP/SignupOTP'));
const SigninOTP = React.lazy(() => import('./pages/SigninOTP/SigninOTP'));
const SignupPhone = React.lazy(() => import('./pages/SignupPhone/SignupPhone'));
const EventPage_inner_Event = React.lazy(() =>
  import('./pages/EventPage/_pages/eventPageComp/EventPage')
);
const EventCheckoutPage = React.lazy(() =>
  import('./pages/EventPage/_pages/eventCheckoutComp/EventCheckoutPage')
);
// import AboutUs from './pages/aboutUs';
// import ContactUs from './pages/contactUs';
// import Careers from './pages/careers';
// import Blogs from './pages/blogs';
// import Home from './pages/home';
// import Gold from './pages/gold';
// import Platinum from './pages/platinum';
// import Letter from './pages/letter';
// import SignUp from './pages/signUp';
// import ThankYou from './pages/thankyou';
// import PrivacyPollicy from './pages/privacyPollicy';
// import Cookies from './pages/cookies';
// import TermsConditions from './pages/termsConditions';
// import Support from './pages/support';
// import info from './pages/info';
// import refund from './pages/refund';
// import BlogPost from './pages/blogPost';
// import BlogPost2 from './pages/blogPost2';
// import BlogPost3 from './pages/blogPost3';
// import PaymentStatus from './pages/PaymentStatus';

const ScrollToTop = (WrappedComponent) => {
  return class extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

// function OpenRoutes() {
//   // const location = useLocation();
//   // console.log(location);
//   // const SuccessPayment = () => <PaymentStatus success={true} />;
//   return (
//     <div className="">
//       <AnimatePresence mode="wait">
//         <Routes>
//           <Route path="/refund" element={ScrollToTop(refund)} />
//           <Route path="/post1" element={ScrollToTop(BlogPost)} />
//           <Route path="/post2" element={ScrollToTop(BlogPost2)} />
//           <Route path="/post3" element={ScrollToTop(BlogPost3)} />

//           <Route path="/info/:id" element={ScrollToTop(info)} />
//           <Route path="/help" element={ScrollToTop(Support)} />

//           <Route path="/cookies" element={ScrollToTop(Cookies)} />
//           <Route path="/terms" element={ScrollToTop(TermsConditions)} />
//           <Route path="/privacy" element={ScrollToTop(PrivacyPollicy)} />

//           <Route path="/platinum" element={ScrollToTop(Platinum)} />
//           {/* <Route
//             path="/platinum/:success?/:session_id?"
//             element={ScrollToTop(Platinum)}
//           /> */}
//           <Route path="/gold" element={ScrollToTop(Gold)} />
//           <Route path="/blogs" element={ScrollToTop(Blogs)} />
//           <Route path="/careers" element={ScrollToTop(Careers)} />
//           <Route path="/contactUs" element={ScrollToTop(ContactUs)} />
//           <Route path="/about" element={ScrollToTop(AboutUs)} />
//           <Route path="/letter" element={ScrollToTop(Letter)} />
//           <Route path="/signup" element={ScrollToTop(SignUp)} />
//           <Route path="/thankyou" element={ScrollToTop(ThankYou)} />
//           <Route path="/checkout" element={ScrollToTop(PaymentStatus)} />
//           {/* <Route
//             path="/paymentsuccess"
//             element={ScrollToTop(SuccessPayment)}
//           /> */}
//           <Route path="/" element={ScrollToTop(Home)} />
//         </Routes>
//       </AnimatePresence>
//     </div>
//   );
// }

const HomeComp = ScrollToTop(Home);
const Home1Comp = ScrollToTop(Home1);
const Home2Comp = ScrollToTop(Home2);
const OneStopWeddingSolutionComp = ScrollToTop(OneStopWeddingSolution);
const RefundComp = ScrollToTop(refund);
const Post1Comp = ScrollToTop(BlogPost);
const Post2Comp = ScrollToTop(BlogPost2);
const Post3Comp = ScrollToTop(BlogPost3);
const Post4Comp = ScrollToTop(BlogPost4);
const InfoComp = ScrollToTop(info);
const SupportComp = ScrollToTop(Support);
const CookiesComp = ScrollToTop(Cookies);
const TermComp = ScrollToTop(TermsConditions);
const PrivacyComp = ScrollToTop(PrivacyPollicy);
const ChildAbusePollicyComp = ScrollToTop(ChildAbusePollicy);
const PlatinumComp = ScrollToTop(Platinum);
const GoldComp = ScrollToTop(Gold);
const BlogComp = ScrollToTop(Blogs);
const CareerComp = ScrollToTop(Careers);
const AboutusComp = ScrollToTop(AboutUs);
const LetterComp = ScrollToTop(Letter);
const SignUpComp = ScrollToTop(SignUp);
const ThankYouComp = ScrollToTop(ThankYou);
const PaymentStatusComp = ScrollToTop(PaymentStatus);
const EventPageComp = ScrollToTop(EventPage);
const PaymentSuccessComp = ScrollToTop(PaymentSuccess);
const PaymentFailedComp = ScrollToTop(PaymentFailed);
const EventPageInnerEvComp = ScrollToTop(EventPage_inner_Event);
const EventPageCheckoutInnerEvComp = ScrollToTop(EventCheckoutPage);

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomeComp />,
  },
  {
    path: '/home1',
    element: <Home1Comp />,
  },
  {
    path: '/home2',
    element: <Home2Comp />,
  },
  {
    path: '/landingdemopageee',
    element: <OneStopWeddingSolutionComp />,
  },
  {
    path: '/refund',
    element: <RefundComp />,
  },
  {
    path: '/post1',
    element: <Post1Comp />,
  },
  {
    path: '/post2',
    element: <Post2Comp />,
  },
  {
    path: '/post3',
    element: <Post3Comp />,
  },
  {
    path: '/post4',
    element: <Post4Comp />,
  },
  {
    path: '/info/:id',
    element: <InfoComp />,
  },
  {
    path: '/help',
    element: <SupportComp />,
  },
  {
    path: '/cookies',
    element: <CookiesComp />,
  },
  {
    path: '/terms',
    element: <TermComp />,
  },
  {
    path: '/privacy',
    element: <PrivacyComp />,
  },
  {
    path: '/child-abuse-policy',
    element: <ChildAbusePollicyComp />,
  },
  {
    path: '/platinum',
    element: <PlatinumComp />,
  },
  {
    path: '/gold',
    element: <GoldComp />,
  },
  {
    path: '/blogs',
    element: <BlogComp />,
  },
  {
    path: '/careers',
    element: <CareerComp />,
  },
  {
    path: '/contactUs',
    element: <ContactUs />,
  },
  {
    path: '/about',
    element: <AboutusComp />,
  },
  {
    path: '/letter',
    element: <LetterComp />,
  },
  {
    path: '/signup',
    element: <SignUpComp />,
  },
  {
    path: '/thankyou',
    element: <ThankYouComp />,
  },
  {
    path: '/checkout',
    element: <PaymentStatusComp />,
  },
  {
    path: '/paymentsuccess',
    element: <PaymentSuccess />,
  },
  {
    path: '/paymentfailed',
    element: <PaymentFailed />,
  },
  {
    path: '/onboard',
    element: <OnboardingPage />
  },
  {
    path: '/signup-or-signin',
    element: <SignupOrSignin />
  },
  {
    path: '/signup-email',
    element: <SignupEmail />
  },
  {
    path: '/signin',
    element: <SigninEmail />
  },
  {
    path: '/signup-otp',
    element: <SignupOTP />
  },
  {
    path: '/signin-otp',
    element: <SigninOTP />
  },
  {
    path: '/signup-phone',
    element: <SignupPhone />
  },
  {
    path: '/event',
    element: <EventPageComp />,
    children: [
      {
        path: '',
        element: <EventPageInnerEvComp />,
      },
      {
        path: 'checkout',
        element: <EventPageCheckoutInnerEvComp />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]);

const OpenRoutesMain = () => {
  return (
    <AnimatePresence mode="wait">
      <RouterProvider router={router} />
    </AnimatePresence>
  );
};
export default OpenRoutesMain;
